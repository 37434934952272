import { NAMESPACES } from 'constants/locales'

import React, { Suspense, memo, useCallback } from 'react'
import useTranslation from 'next-translate/useTranslation'
import ErrorBoundary from 'components/error-boundary/error-boundary'
import { routes } from 'utils/routes'
import { trackExperimentEvent, trackSignUp } from 'components/tracking/utils'
import { MediaTextLayout } from 'components/modules/media-text-module'
import SuspenseFallback from 'components/suspense-fallback'
import dynamic from 'next/dynamic'
import HeadlineModule, {
  HeadlineModuleLayout,
} from 'components/modules/headline-module'

import TrackingLocation, {
  TrackingLocationNames,
} from './common/tracking-location'
import { HOMEPAGE_REFRESH_TEST_ID } from './constants'

const MediaTextModule = dynamic(
  () => import('./common/media-text-module/media-text-module')
)
const CtaSection = dynamic(() => import('./cta-section'))
const ContentGridSection = dynamic(() => import('./content-grid-section'))
const IntegrationsSection = dynamic(() => import('./integrations-section'))
const WhyTypeformSection = dynamic(() => import('./why-typeform-section'))
const ClosingSection = dynamic(() => import('./closing-section'))

const HomepageBelowFold = () => {
  const { t, lang } = useTranslation(NAMESPACES.HOME)

  const handleSignupClick = useCallback(
    (section: string) => () => {
      trackExperimentEvent({
        testId: HOMEPAGE_REFRESH_TEST_ID,
        name: `homepage_refresh_signup_cta_click_${section}`,
      })
      trackSignUp({
        ctaText: 'Sign up',
        itemType: 'link',
        linkUrl: routes(lang).signup,
        location: `body:${section}`,
      })
    },
    [lang]
  )

  return (
    <>
      <ErrorBoundary>
        <Suspense fallback={<SuspenseFallback />}>
          <HeadlineModule
            layout={HeadlineModuleLayout.Compact}
            title={t('homepage.refresh.section-0.title')}
          />
        </Suspense>
      </ErrorBoundary>

      <ErrorBoundary>
        <TrackingLocation name={TrackingLocationNames.MediaTextSection1}>
          <Suspense fallback={<SuspenseFallback />}>
            <MediaTextModule
              data-testid='section-1'
              eyebrow={t('homepage.refresh.section-1.eyebrow')}
              mediaSlug='section-1'
              layout={MediaTextLayout.MediaRight}
              title={t('homepage.refresh.section-1.title')}
              body={t('homepage.refresh.section-1.body')}
              cta={{
                href: routes(lang).signup,
                label: t('homepage.refresh.section-1.cta.label'),
                onClick: handleSignupClick('section-1'),
              }}
            />
          </Suspense>
        </TrackingLocation>
      </ErrorBoundary>

      <ErrorBoundary>
        <TrackingLocation name={TrackingLocationNames.MediaTextSection2}>
          <Suspense fallback={<SuspenseFallback />}>
            <MediaTextModule
              data-testid='section-2'
              eyebrow={t('homepage.refresh.section-2.eyebrow')}
              mediaSlug='section-2'
              layout={MediaTextLayout.MediaLeft}
              title={t('homepage.refresh.section-2.title')}
              body={t('homepage.refresh.section-2.body')}
              cta={{
                href: routes(lang).signup,
                label: t('homepage.refresh.section-2.cta.label'),
                onClick: handleSignupClick('section-2'),
              }}
            />
          </Suspense>
        </TrackingLocation>
      </ErrorBoundary>

      <ErrorBoundary>
        <Suspense fallback={<SuspenseFallback />}>
          <CtaSection />
        </Suspense>
      </ErrorBoundary>

      <ErrorBoundary>
        <Suspense fallback={<SuspenseFallback />}>
          <ContentGridSection />
        </Suspense>
      </ErrorBoundary>

      <ErrorBoundary>
        <Suspense fallback={<SuspenseFallback />}>
          <IntegrationsSection />
        </Suspense>
      </ErrorBoundary>

      <ErrorBoundary>
        <Suspense fallback={<SuspenseFallback />}>
          <WhyTypeformSection />
        </Suspense>
      </ErrorBoundary>

      <ErrorBoundary>
        <Suspense fallback={<SuspenseFallback />}>
          <ClosingSection />
        </Suspense>
      </ErrorBoundary>
    </>
  )
}

HomepageBelowFold.displayName = 'HomepageBelowFold'

export default memo(HomepageBelowFold)

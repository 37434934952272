import mediaqueries from '@typeform/ginger/dist/util/mediaqueries'
import styled from 'styled-components'

import { CornersStyle, TModuleContainerProps } from './types'

type TBorderRadius = (radius: string) => string

const borderRadius: {
  [CornersStyle.Default]: TBorderRadius
  [CornersStyle.RoundTop]: TBorderRadius
  [CornersStyle.RoundBottom]: TBorderRadius
  [CornersStyle.RoundAll]: TBorderRadius
} = {
  [CornersStyle.Default]: () => '0',
  [CornersStyle.RoundTop]: radius => `${radius} ${radius} 0 0`,
  [CornersStyle.RoundBottom]: radius => `0 0 ${radius} ${radius}`,
  [CornersStyle.RoundAll]: radius => radius,
}

export const Section = styled.section<TModuleContainerProps>`
  background-color: ${({ backgroundColor }) => backgroundColor};
  color: ${({ textColor }) => textColor};
  border-radius: ${({ cornersStyle }) => {
    return borderRadius[cornersStyle!]('64px')
  }};
  overflow: hidden;

  ${mediaqueries.md`
    border-radius: ${({ cornersStyle }: TModuleContainerProps) => {
      return borderRadius[cornersStyle!]('96px')
    }};
  `}
`

import { useCallback, useMemo } from 'react'
import { BREAKPOINTS } from '@typeform/ginger/dist/constants/design'
import { Device } from '@typeform/ginger/dist/constants/device'
import { onTrackItemClickEvent } from '@typeform/ginger/dist/util/tracking'
import { ButtonSize } from 'components/atoms/button'
import { TActionBarMouseEventHandler } from 'components/molecules/action-bar'
import RenderHtml from 'components/atoms/render-html'
import useMediaWithDevice from '@typeform/ginger/dist/hooks/use-media-with-device'

import {
  Body,
  ContentContainerGridItem,
  Eyebrow,
  MediaTextModuleContainer,
  MediaTextGrid,
  MediaContainerGridItem,
  MediaTextActionBar,
  Title,
} from './media-text-module.styles'
import { TMediaTextModuleProps } from './media-text.types'
import { defaultProps, testIds } from './media-text.constants'

const MediaTextModule = ({
  layout,
  backgroundColor,
  palette,
  eyebrow,
  title,
  body,
  actionBar,
  media,
  smallMedia,
  cornersStyle,
  trackEvent,
  trackExperimentEvent,
  ...rest
}: TMediaTextModuleProps) => {
  const isSmallScreen = useMediaWithDevice(
    `(max-width: ${BREAKPOINTS.md - 1}px)`,
    Device.Mobile
  )

  const handleActionClick: TActionBarMouseEventHandler = useCallback(
    ({ link }) => {
      onTrackItemClickEvent(link, trackEvent, trackExperimentEvent)
    },
    [trackEvent, trackExperimentEvent]
  )

  const renderedMediaContainer = useMemo(() => {
    return (
      <MediaContainerGridItem
        data-testid={testIds.Media}
        layout={layout}
        media={media}
        smallMedia={smallMedia}
      />
    )
  }, [media, smallMedia, layout])

  const renderedTitle = useMemo(() => {
    if (!title) {
      return null
    }

    if (typeof title === 'string') {
      return <RenderHtml as='span' html={title} />
    }
    return title
  }, [title])

  return (
    <MediaTextModuleContainer
      data-testid={testIds.Container}
      layout={layout}
      palette={palette}
      backgroundColor={backgroundColor}
      cornersStyle={cornersStyle}
      {...rest}
    >
      <MediaTextGrid>
        {!isSmallScreen && renderedMediaContainer}

        <ContentContainerGridItem data-testid={testIds.Content} layout={layout}>
          {eyebrow && (
            <Eyebrow data-testid={testIds.Eyebrow}>{eyebrow}</Eyebrow>
          )}

          {<Title data-testid={testIds.Title}>{renderedTitle}</Title>}

          {isSmallScreen && renderedMediaContainer}

          <Body
            data-testid={testIds.Body}
            forwardedAs={typeof body !== 'string' ? 'div' : undefined}
          >
            {body}
          </Body>
          {actionBar && (
            <MediaTextActionBar
              {...actionBar}
              data-testid={testIds.ActionBar}
              size={ButtonSize.Large}
              palette={palette}
              onClick={handleActionClick}
            />
          )}
        </ContentContainerGridItem>
      </MediaTextGrid>
    </MediaTextModuleContainer>
  )
}

MediaTextModule.defaultProps = defaultProps

export default MediaTextModule

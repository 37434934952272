import { TModuleContainerProps } from 'components/modules/module-container'

export type THeadlineModuleProps = TModuleContainerProps & {
  title: string
  // TODO: Once src/components/typography/headline is migrated to TS we can use HeadlineProps
  titleProps?: object
  description?: string
  layout?: HeadlineModuleLayout
  alignment?: HeadlineModuleAlignment
  variant?: HeadlineModuleVariant
  isModuleChild?: boolean
}

export enum HeadlineModuleVariant {
  Default = 'default',
  Expressive = 'expressive',
}

export enum HeadlineModuleLayout {
  Default = 'default',
  Compact = 'compact',
}

export enum HeadlineModuleAlignment {
  Default = 'default',
  Leading = 'leading',
}

export type TSizeConfig = {
  size: string
  extraSmallViewportsSize: string
  smallViewportsSize: string
  mediumViewportsSize: string
  largeViewportsSize: string
}

import { ReactNode } from 'react'
import { Palette } from '@typeform/ginger/dist/constants/palettes'
import {
  TTrackEventFunction,
  TTrackExperimentEventFunction,
} from '@typeform/ginger/dist/util/tracking-types'
import {
  CornersStyle,
  TModuleContainerProps,
} from 'components/modules/module-container'
import { TActionBarProps } from 'components/molecules/action-bar/action-bar.types'

export enum MediaTextLayout {
  MediaLeft = 'media-left',
  MediaRight = 'media-right',
}
export type TMediaTextModuleProps = {
  layout: MediaTextLayout
  backgroundColor?: string
  palette: Palette
  eyebrow?: ReactNode
  title: ReactNode
  body: ReactNode
  actionBar?: TActionBarProps
  media: ReactNode
  smallMedia?: ReactNode
  cornersStyle: CornersStyle
  trackEvent?: TTrackEventFunction
  trackExperimentEvent?: TTrackExperimentEventFunction
}

export type TMediaTextModuleContainerProps = TModuleContainerProps & {
  layout: MediaTextLayout
  palette: Palette
}

export type TMediaTextGridItemProps = {
  layout: MediaTextLayout
}

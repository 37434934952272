import React, { memo } from 'react'

import { Section } from './module-container.styles'
import { defaultProps } from './constants'
import { TModuleContainerProps } from './types'

const ModuleContainer: React.FC<TModuleContainerProps> = ({
  backgroundColor = defaultProps.backgroundColor,
  textColor = defaultProps.textColor,
  cornersStyle = defaultProps.cornersStyle,
  ...rest
}) => {
  return (
    <Section
      backgroundColor={backgroundColor}
      textColor={textColor}
      cornersStyle={cornersStyle}
      {...rest}
    />
  )
}

export default memo(ModuleContainer)

import { SIZE } from '@typeform/ginger/dist/components/typography/headline/next'

import { HeadlineModuleVariant, TSizeConfig } from './headline-module.types'

export const testIds = {
  Container: 'headline-module',
  Description: 'headline-module--description',
}

export const HeadlineVariantConfig: {
  [key in HeadlineModuleVariant]: TSizeConfig
} = {
  [HeadlineModuleVariant.Default]: {
    size: SIZE.TWO,
    extraSmallViewportsSize: SIZE.TWO,
    smallViewportsSize: SIZE.THREE,
    mediumViewportsSize: SIZE.THREE,
    largeViewportsSize: SIZE.FOUR,
  },
  [HeadlineModuleVariant.Expressive]: {
    size: SIZE.THREE,
    extraSmallViewportsSize: SIZE.THREE,
    smallViewportsSize: SIZE.FOUR,
    mediumViewportsSize: SIZE.FOUR,
    largeViewportsSize: SIZE.FIVE,
  },
}

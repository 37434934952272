import { DEFAULT_PALETTE } from '@typeform/ginger/dist/constants/palettes'
import { CornersStyle } from 'components/modules/module-container'

import { MediaTextLayout } from './media-text.types'

export const defaultProps = {
  layout: MediaTextLayout.MediaLeft,
  palette: DEFAULT_PALETTE,
  eyebrow: '',
  cornersStyle: CornersStyle.Default,
}

export const testIds = {
  Container: 'media-text-module',
  Media: 'media-text-module--media',
  Content: 'media-text-module--content',
  Eyebrow: 'media-text-module--eyebrow',
  Title: 'media-text-module--title',
  Body: 'media-text-module--body',
  ActionBar: 'media-text-module--action-bar',
}

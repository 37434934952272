import React from 'react'

export enum CornersStyle {
  Default = 'Default',
  RoundTop = 'Round top corners',
  RoundBottom = 'Round bottom corners',
  RoundAll = 'Round all corners',
}

export type TModuleContainerProps = React.HTMLAttributes<HTMLDivElement> & {
  cornersStyle?: CornersStyle
  textColor?: string
  backgroundColor?: string
}

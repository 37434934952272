import ModuleContainer from 'components/modules/module-container'

import {
  Title,
  Description,
  StyledMarkdown,
  StyledGrid,
} from './headline-module.styles'
import {
  HeadlineModuleLayout,
  HeadlineModuleAlignment,
  THeadlineModuleProps,
  HeadlineModuleVariant,
} from './headline-module.types'
import { HeadlineVariantConfig, testIds } from './headline-module.constants'

const HeadlineModule = (props: THeadlineModuleProps) => {
  const {
    title,
    titleProps,
    description,
    layout = HeadlineModuleLayout.Default,
    alignment = HeadlineModuleAlignment.Default,
    variant = HeadlineModuleVariant.Default,
    isModuleChild = false,
    ...rest
  } = props

  const variantConfig = HeadlineVariantConfig[variant]

  return (
    <ModuleContainer data-testid={testIds.Container} {...rest}>
      <StyledGrid {...{ layout, alignment, isModuleChild }}>
        {!!title && (
          <Title
            id={encodeURIComponent(title)}
            {...titleProps}
            {...variantConfig}
          >
            <StyledMarkdown>{title}</StyledMarkdown>
          </Title>
        )}
        {!!description && (
          <Description
            forwardedAs='div'
            data-qa='headline-description'
            data-testid={testIds.Description}
          >
            <StyledMarkdown>{description}</StyledMarkdown>
          </Description>
        )}
      </StyledGrid>
    </ModuleContainer>
  )
}

HeadlineModule.displayName = 'HeadlineModule'

export default HeadlineModule

import mediaQueries from '@typeform/ginger/dist/util/mediaqueries'
import styled, { css } from 'styled-components'
import Markdown from 'components/atoms/markdown'
import Headline from '@typeform/ginger/dist/components/typography/headline/next'
import Text from '@typeform/ginger/dist/components/typography/text/next'
import Grid from 'components/atoms/grid-container/next'

import {
  HeadlineModuleAlignment,
  HeadlineModuleLayout,
} from './headline-module.types'

export const StyledGrid = styled(Grid)<{
  layout: HeadlineModuleLayout
  alignment: HeadlineModuleAlignment
  isModuleChild: boolean
}>`
  ${({ layout, alignment, isModuleChild }) => css`
    row-gap: 16px;

    ${isModuleChild &&
    css`
      padding: 0;
    `}

    ${!isModuleChild && `margin-top: 64px`};
    ${!isModuleChild &&
    layout === HeadlineModuleLayout.Default &&
    `margin-bottom: 64px`};

    ${mediaQueries.sm`
      ${!isModuleChild && `margin-top: 72px`};
      ${
        !isModuleChild &&
        layout === HeadlineModuleLayout.Default &&
        `margin-bottom: 72px`
      };
    `}

    ${mediaQueries.md`
      ${!isModuleChild && `margin-top: 80px`};
      ${
        !isModuleChild &&
        layout === HeadlineModuleLayout.Default &&
        `margin-bottom: 80px`
      };
    `}

    ${mediaQueries.lg`
      row-gap: 24px;
    `}


    ${mediaQueries.xl`
      ${!isModuleChild && `margin-top: 96px`};
      ${
        !isModuleChild &&
        layout === HeadlineModuleLayout.Default &&
        `margin-bottom: 96px`
      };
    `}

    > * {
      grid-column: 1 / -1;
      text-align: ${alignment === HeadlineModuleAlignment.Default
        ? 'center'
        : 'leading'};

      ${mediaQueries.sm`
        ${
          alignment === HeadlineModuleAlignment.Default
            ? `grid-column: 2 / -2`
            : `grid-column: 1 / -1`
        };
      `};

      ${mediaQueries.md`
        ${
          alignment === HeadlineModuleAlignment.Default
            ? `grid-column: 3 / -3`
            : `grid-column: 1 / -1`
        };
      `};

      ${mediaQueries.lg`
        ${
          alignment === HeadlineModuleAlignment.Default
            ? `grid-column: 3 / -3`
            : `grid-column: 1 / -1`
        };
      `};
    }
  `}
`

export const Title = styled(Headline)`
  margin: 0;
`

export const Description = styled(Text)`
  font-size: 16px;

  ul {
    list-style-type: none; // Remove bullets
    padding: 0; // Remove padding for side margin
    margin: 8px 0; // Set top margins for empty line bellow first description
  }

  ${mediaQueries.lg`
    font-size: 20px;
  `};
`

export const StyledMarkdown = styled(Markdown)`
  color: ${({ color }) => color ?? 'inherit'};
  a {
    text-decoration: underline;
  }

  p {
    margin: 0;
  }
`

import styled from 'styled-components'
import mediaQueries from '@typeform/ginger/dist/util/mediaqueries'
import { spacing } from '@typeform/ginger/dist/util/spacing'
import { Palette } from '@typeform/ginger/dist/constants/palettes'
import { NeutralColors } from '@typeform/ginger/dist/constants/colors'
import Grid, {
  GridItem,
  gridPosition,
} from 'components/atoms/grid-container/next'
import Text, {
  SIZE as TEXT_SIZE,
} from '@typeform/ginger/dist/components/typography/text/next'
import Headline, {
  SIZE as HEADLINE_SIZE,
} from '@typeform/ginger/dist/components/typography/headline/next'
import ActionBar from 'components/molecules/action-bar'
import ModuleMediaContainer from 'components/atoms/module-media-container'
import ModuleContainer from 'components/modules/module-container'

import {
  TMediaTextModuleContainerProps,
  TMediaTextGridItemProps,
  MediaTextLayout,
} from './media-text.types'

export const MediaTextModuleContainer = styled(
  ModuleContainer
).attrs<TMediaTextModuleContainerProps>(
  ({ palette, textColor, backgroundColor, ...props }) => ({
    ...props,
    textColor:
      textColor ||
      (palette === Palette.Negative ? NeutralColors.White : NeutralColors.Ink),
    backgroundColor:
      backgroundColor ||
      (palette === Palette.Negative ? NeutralColors.Ink : NeutralColors.White),
  })
)<TMediaTextModuleContainerProps>`
  padding: ${spacing(8, 0)};

  ${mediaQueries.sm`
    padding: ${spacing(9, 0)};
  `}

  ${mediaQueries.md`
    padding: ${spacing(10, 0)};
  `}

  ${mediaQueries.xl`
    padding: ${spacing(12, 0)};
  `}
`

export const MediaTextGrid = styled(Grid)`
  row-gap: ${spacing(7)};

  ${mediaQueries.sm`
    row-gap: ${spacing(8)};
  `}
  ${mediaQueries.md`
    row-gap: ${spacing(9)};
  `}
  ${mediaQueries.xl`
    row-gap: ${spacing(8)};
  `};
`

const MediaGridPositons = {
  [MediaTextLayout.MediaLeft]: {
    base: gridPosition().fill(),
    sm: gridPosition().pad(2),
    md: gridPosition().start(2).size(5),
  },
  [MediaTextLayout.MediaRight]: {
    base: gridPosition().fill(),
    sm: gridPosition().pad(2),
    md: gridPosition().end(-2).size(5),
  },
}

export const ContentGridPositions = {
  [MediaTextLayout.MediaLeft]: {
    base: gridPosition().fill(),
    sm: gridPosition().pad(2),
    md: gridPosition().end(-2).size(4),
  },
  [MediaTextLayout.MediaRight]: {
    base: gridPosition().fill(),
    sm: gridPosition().pad(2),
    md: gridPosition().start(2).size(4),
  },
}

export const MediaContainerGridItem = styled(
  ModuleMediaContainer
).attrs<TMediaTextGridItemProps>(({ layout, ...props }) => ({
  ...props,
  forwardedAs: GridItem,
  column: MediaGridPositons[layout],
}))<TMediaTextGridItemProps>`
  margin-top: ${spacing(4)};

  ${mediaQueries.md`
    margin-top: 0;
  `}
`

export const ContentContainerGridItem = styled(
  GridItem
).attrs<TMediaTextGridItemProps>(({ layout, ...props }) => ({
  ...props,
  column: ContentGridPositions[layout],
}))<TMediaTextGridItemProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  grid-row: 1;

  ${mediaQueries.md`
    text-align: left;
    align-items: flex-start;
  `}
`

export const Eyebrow = styled(Text).attrs({ size: TEXT_SIZE.XS })`
  text-transform: uppercase;
  margin-bottom: ${spacing(3)};

  ${mediaQueries.md`
    margin-bottom: ${spacing(2)};
  `}

  ${mediaQueries.xl`
    margin-bottom: ${spacing(3)};
  `}
`

export const Title = styled(Headline).attrs({
  forwardedAs: 'h2',
  extraSmallViewportsSize: HEADLINE_SIZE.ONE,
  smallViewportsSize: HEADLINE_SIZE.ONE,
  mediumViewportsSize: HEADLINE_SIZE.TWO,
  largeViewportsSize: HEADLINE_SIZE.THREE,
})``

export const Body = styled(Text).attrs({
  extraSmallViewportsSize: TEXT_SIZE.S,
  smallViewportsSize: TEXT_SIZE.S,
  mediumViewportsSize: TEXT_SIZE.M,
  largeViewportsSize: TEXT_SIZE.M,
})`
  margin-top: ${spacing(4)};

  ${mediaQueries.md`
    margin-top: ${spacing(2)};
  `}

  ${mediaQueries.xl`
    margin-top: ${spacing(3)};
  `}

  > p:first-of-type {
    margin-top: 0;
    padding-top: 0;
  }

  > p:last-of-type {
    margin-bottom: 0;
    padding-bottom: 0;
  }
`

export const MediaTextActionBar = styled(ActionBar)`
  margin-top: ${spacing(4)};

  ${mediaQueries.md`
    margin-top: ${spacing(3)};
  `}

  ${mediaQueries.xl`
    margin-top: ${spacing(4)};
  `}
`
